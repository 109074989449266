import { template as template_2361894b8c3547b3b65b103616d5d224 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2361894b8c3547b3b65b103616d5d224(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_4ec16b06779c4c49a5842c4ff530d123 } from "@ember/template-compiler";
const WelcomeHeader = template_4ec16b06779c4c49a5842c4ff530d123(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_e17be934fa4e4d4e81b54bbced5fcb48 } from "@ember/template-compiler";
const FKText = template_e17be934fa4e4d4e81b54bbced5fcb48(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
